//---------------
// 404 Page
//---------------

// PACKAGES
import React from "react"

// COMPONENTS
import Layout from "../layout/Layout"

// COMPONENT BUILD
const NotFound = () => {
    // JSX TEMPLATE
    return (
        <Layout>
            <div style={{height:`100vh`}}>
            <h1>No such page!</h1>
            </div>
        </Layout>
    )
}

export default NotFound